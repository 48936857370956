import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertComp from "../../component/alert/AlertComp";
import { useDaftarTransaksi } from "./daftarTransaksiFunction";
import { faInfo,  faSearch } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import { PrintPage } from "../Print/PrintPage";
import PagePagination from "../../component/globalComponent/PagePagination";

const DaftarTransaksiPage = (props) => {
    const { isLogin, pin, handleChangePin, handleBlurPin, handleSubmitPassword, alertValue, handleCloseAlert,
        listData, handleSearch, handleChangeSearch, handleKeyDownSearch, handleKeyDownPin,
        selectedInvoice, handleCloseModal, modalValue, handleDetail, resendHook,
        componentRef, printThePage, handleKembali, handlePaginate
     } = useDaftarTransaksi();

     console.log('page is >>>>>', listData.page)
     
    return(
        <div className='w-full h-full relative'>
            <div className='hidden'>
                <div className="grid grid-cols-1 container-home ">

                    <PrintPage ref={componentRef} customerDetail={selectedInvoice}/>

                </div>
            </div>
            { modalValue.show &&
                <div className='absolute w-full h-screen bg-black/[.54] z-10' onClick={handleCloseModal}>
                    <div className="w-full h-full flex flex-row justify-center items-center">
                        <div className='w-2/4 bg-white pb-10 rounded-lg shadow-lg p-7 text-sm'>
                            { modalValue.type === 'detail' &&
                                <div>
                                    <table className='[&>tbody>tr>td]:px-4 [&>tbody>tr>td]:py-[1px] [&>tbody>tr>td:first-child]:pl-0 [&>tbody>tr>td:first]:pt-0'>
                                        <tbody>
                                            <tr>
                                                <td className=''>Nama Konsumen</td>
                                                <td className=''>:</td>
                                                <td className=''>{selectedInvoice?.konsumen_name}</td>
                                            </tr>
                                            <tr>
                                                <td className=''>HP</td>
                                                <td className=''>:</td>
                                                <td className=''>{selectedInvoice?.hp}</td>
                                            </tr>
                                            <tr>
                                                <td className=''>Total</td>
                                                <td className=''>:</td>
                                                <td className=''>{selectedInvoice?.total?.toLocaleString()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='mt-7'/>
                                    <table 
                                        className='[&>tbody>tr>td]:px-4 [&>tbody>tr>td]:py-1 [&>tbody>tr>td]:border-b text-xs
                                            [&>thead>tr>th]:px-4 [&>thead>tr>th]:border-b [&>thead>tr>th]:bg-gray-100 [&>thead>tr>th]:py-2 w-full
                                        '
                                    >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Invoice / Produk</th>
                                                <th className='text-right'>Qty</th>
                                                <th className='text-right'>Harga</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { selectedInvoice?.list_invoice?.map((post, index) => {
                                                return(
                                                    <Fragment key={index}>
                                                        <tr key={index}>
                                                            <td></td>
                                                            <td colSpan={3} className='text-left font-bold'>{post.noinvoice_odoo}</td>
                                                        </tr>
                                                        { post?.detail?.map((detail, indexDetail) => {
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{indexDetail+1}</td>
                                                                    <td>{detail.produk_name}</td>
                                                                    <td className='text-right'>{detail.qty?.toLocaleString()}</td>
                                                                    <td className='text-right'>{detail.harga?.toLocaleString()}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="flex flex-row justify-end mt-5 text-sm font-thin">
                                        { selectedInvoice.is_paid &&
                                            <button className='px-5 py-2 bg-blue-500 text-white rounded-l' onClick={printThePage}>Cetak</button>
                                        }
                                        { selectedInvoice.is_paid &&
                                            <button className='px-5 py-2 bg-blue-500 text-white rounded-l' onClick={resendHook}>Resend</button>
                                        }
                                        <button className='px-5 py-2 bg-gray-500 text-white rounded-r' onClick={handleCloseModal}>Cancel</button>
                                    </div>      
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            <AlertComp
				text={alertValue.text}
				color={alertValue.color}
				isOpen={alertValue.show}
				onClose={handleCloseAlert}
			/>
            { !isLogin &&
                <div className='flex flex-row justify-center items-center w-full h-full'>
                    <div className='shadow-lg rounded-lg bg-white p-5'>
                        <div className='font-bold text-[17px] mb-5'>Masukan PIN</div>
                        <input 
                            className='
                                focus:outline-1 
                                focus:outline-none border border-gray-400  px-2 py-3 rounded-md
                                w-full
                                z-3
                            ' 
                            value={pin.value} 
                            onChange={handleChangePin} 
                            onBlur={handleBlurPin}
                            type="password"
                            onKeyDown={handleKeyDownPin}
                        />
                        <button className='w-full bg-blue-500 text-white py-2 rounded-md mt-3' onClick={handleSubmitPassword}>Submit</button>
                    </div>
                </div>
            }
            { isLogin &&
                <div className='w-full h-full bg-white p-7 text-sm'>                    
                    <div className="flex flex-row justify-end mb-5">
                        
                        <button className='ml-3 bg-blue-500 text-white px-6 rounded h-[38px] mr-5' onClick={() => handleKembali()}>Kembali</button>                        
                        <div className="relative">
                            <input
                                className='focus:outline-1 
                                focus:outline-none border border-gray-200  px-2 py-2 rounded-md
                                w-full'
                                value={listData.search}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDownSearch}
                            />
                            <button className='absolute top-2 right-3 text-gray-400' onClick={handleSearch}>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </div>
                        
                    </div>
                    <table className='table w-full border-separate border-spacing-0 [&>thead>tr>th]:py-4 [&>tbody>tr>td]:py-2'>
                        <thead>
                            <tr className="py-7">
                                <th className='bg-gray-100 py-6 border-b px-3 text-right'>#</th>
                                <th className='bg-gray-100 py-6 border-b px-3 text-left'>HP</th>
                                <th className='bg-gray-100 py-6 border-b px-3 text-left'>NOPOL</th>
                                <th className='bg-gray-100 py-6 border-b px-3 text-left'>Konsumen Name</th>
                                <th className='bg-gray-100 py-6 border-b px-3 text-left'>Tanggal</th>
                                <th className='bg-gray-100 py-6 border-b px-3 text-center'>IS Paid</th>
                                <th className='bg-gray-100 py-6 border-b px-3 text-right'>Total</th>
                                <th className='bg-gray-100 py-6 border-b px-3 text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { listData?.data?.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td className="border-b px-3 text-right">{listData.offset+index+1}</td>
                                        <td className='border-b px-3'>{post.hp}</td>
                                        <td className='border-b px-3'>{post.nopol}</td>
                                        <td className='border-b px-3'>{post.konsumen_name}</td>
                                        <td className='border-b px-3'>{post.tanggal_display}</td>
                                        <td className='border-b px-3 text-center'>{post.is_paid ? 
                                            <span className='px-3 text-xs text-white rounded bg-green-500'>Paid</span>
                                            :
                                            <span className='px-3 text-xs text-white rounded bg-red-500'>UnPaid</span>
                                        }</td>
                                        <td className='border-b text-right'>{post.total?.toLocaleString()}</td>
                                        <td className="text-center border-b">                                            
                                            <button className='bg-cyan-500 text-white px-2 py-1 text-xs rounded ml-2' onClick={() => handleDetail(post)}><FontAwesomeIcon icon={faInfo} /></button>
                                        </td>
                                    </tr>
                                )
                            }) }
                        </tbody>
                    </table>
                    <div className='mt-2' />
                    <PagePagination
                        data={listData.obj}
                        handlePagination={handlePaginate}
                        page={listData.page}
                        lastPage={listData.lastPage}
                    />                    
                </div>
            }
        </div>
    )
}
export default DaftarTransaksiPage;